import { Builder_, IconSvg } from 'monica-alexandria'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { get_lead_history } from '../../../../services/api/Leads'

const LeadsHistory = styled.div``

const LeadsHistoryList = styled.ul`

`

const LeadsHistoryItem = styled.li`
    margin-top: 2rem;
`

const LeadsHistoryGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    grid-gap: 2rem;
    margin-top: 1rem;
`

const LeadsHistoryAction = styled.div`
    border: 2px dashed var(--greyDark);
    padding: var(--normalPads);
    border-radius: var(--normalRadius);
    color: ${p => p.theme.color};
    position: relative;
    overflow: hidden;
    grid-column: ${p => p.first ? 'span 3' : 'span 1'};

    aside{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: .3;
        display: block;
    }
`

const LeadsHistoryFrom = styled(LeadsHistoryAction)`

    p{
        padding-top: 1rem;
    }
    aside{
        background: var(--error);
    }
`

const LeadsHistoryIcon = styled.div`

    svg{
        fill: ${p => p.theme.color};
        width: 3rem;
    }
`

const LeadsHistoryTo = styled(LeadsHistoryAction)`
    p{
        padding-top: 1rem;
    }
    aside{
        background: var(--success);
    }
`

const LeadsHistorySingle = styled.div`
    padding-bottom: 2rem;
    h6{
        padding-bottom: 0.5rem;
    }
`

const Action = styled.div`
	padding: 1rem;
    border-radius: 1rem;
    background: ${p => p.theme.mid};
    display: inline-block;
	margin-top: 1rem;
	width: 250px;

	h2 {
		font-weight: bold;
		padding: .5rem;
	}

	h5 {
		padding: .5rem;
		opacity: 0.8;
	}
`
export default function Leads_History(props) {

    const {t} = useTranslation();
    const [leadHistory, setLeadHistory] = useState([]) 
   
    // const slicedPrev = props.selectedLead.history?.slice(1).map((item, i-1));
    const getLeadHistory = () =>{
        get_lead_history(props.selectedLead?._id)
        .then(res=>{
            console.log('Res', res);

            const filteredHistory = res.filter(item => {
                return !(item.type === "action" && item.action === "Note");
            });
            setLeadHistory(filteredHistory)
            
        })
    }

    const slicedHistory = leadHistory?.map((item, i, arr) => {
        // Find the previous item of the same type
        //creates a new array containing all items before the current item
        const prevItem = arr.slice(0, i).reverse().find(prev => prev.type === item.type);
        
        // Return the current item along with the previous item of the same type
        return { current: item, previous: prevItem };
    });
    

  useEffect(() => {
    getLeadHistory();
  }, []);

  console.log('leadHistory', leadHistory);
  
  console.log('sliced', slicedHistory);
  
  
   return (
        <LeadsHistory>
            <LeadsHistoryList>
                {slicedHistory?.map(({ current, previous }, i) => {
                    // Define duplicate check based on type and displayed fields
                    let isDuplicate = false;

                    if (current.type === 'history' && previous) {
                        // For 'history' type, compare seller fields
                        isDuplicate = current.seller?.firstname === previous.seller?.firstname &&
                                      current.seller?.lastname === previous.seller?.lastname &&
                                      current.seller?.username === previous.seller?.username;
                    // } else if (current.type === 'custom' && previous) {
                    //     // For 'custom' type, compare 'name' and 'adminId'
                    //     isDuplicate = current.name === previous.name &&
                    //                   current.adminId === previous.adminId;
                    } 
                    // else if (current.type === 'auto' && previous) {
                    //     // For 'auto' type, compare 'name' (or any relevant fields for this type)
                    //     isDuplicate = current.name === previous.name;
                    // } 
                    else if (current.type === 'action' && previous) {
                        // For 'auto' type, compare 'name' (or any relevant fields for this type)
                        if (current?.action === 'Note' || previous?.action ==='Note') {
                            return null;
                        }
                    }

                    // Skip rendering if current and previous are duplicates
                    if (isDuplicate) {
                        return null;
                    }

                    // Render based on the type
                    return current.type === 'history' ? (
                        <LeadsHistoryItem key={i}>
                            <h6>{`${new Date(current.createdAt).toLocaleDateString('el-GR')}, ${new Date(current.createdAt).toLocaleTimeString('el-GR')}`} | Assigned seller</h6>
                            <LeadsHistoryGrid>
                                {previous && (
                                    <>
                                        <LeadsHistoryFrom>
                                            <aside></aside>
                                            <h3>{previous?.seller?.firstname} {previous?.seller?.lastname}</h3>
                                            <h4>{previous?.seller?.username?.split('@')[0]}</h4>
                                            <p>by: {previous?.assignedBy}</p>
                                        </LeadsHistoryFrom>
                                        <LeadsHistoryIcon>
                                            <IconSvg Icon="Next" />
                                        </LeadsHistoryIcon>
                                    </>
                                )}
                                <LeadsHistoryTo first={!previous}>
                                    <aside></aside>
                                    <h3>{current?.seller?.firstname} {current?.seller?.lastname}</h3>
                                    <h4>{current?.seller?.username?.split('@')[0]}</h4>
                                    <p>by: {current?.assignedBy}</p>
                                </LeadsHistoryTo>
                            </LeadsHistoryGrid>
                        </LeadsHistoryItem>
                    // ) 
                    // : current?.type === 'custom' ? (
                    //     <LeadsHistoryItem key={i}>
                    //         <h6>{`${new Date(current.createdAt).toLocaleDateString('el-GR')}, ${new Date(current.createdAt).toLocaleTimeString('el-GR')}`} | Lead status</h6>
                    //         <LeadsHistoryGrid>
                    //             {previous && (
                    //                 <>
                    //                     <LeadsHistoryFrom>
                    //                         <aside></aside>
                    //                         <h3>{previous?.name}</h3>
                    //                         {previous?.adminId ? (
                    //                             <p>by: {previous.adminId}</p>
                    //                         ) : (
                    //                             <p>{t("Automated tag")}</p>
                    //                         )}
                    //                     </LeadsHistoryFrom>
                    //                     <LeadsHistoryIcon>
                    //                         <IconSvg Icon="Next" />
                    //                     </LeadsHistoryIcon>
                    //                 </>
                    //             )}
                    //             <LeadsHistoryTo first={!previous}>
                    //                 <aside></aside>
                    //                 <h3>{current?.name}</h3>
                    //                 {current?.adminId ? (
                    //                     <p>by: {current.adminId}</p>
                    //                 ) : (
                    //                     <p>{t("Automated tag")}</p>
                    //                 )}
                    //             </LeadsHistoryTo>
                    //         </LeadsHistoryGrid>
                    //     </LeadsHistoryItem>
                    ) : current?.type === 'auto' ? (
                        <LeadsHistoryItem key={i}>
                            <h6>{`${new Date(current.createdAt).toLocaleDateString('el-GR')}, ${new Date(current.createdAt).toLocaleTimeString('el-GR')}`} | Register status</h6>
                            <LeadsHistoryGrid>
                                <LeadsHistoryTo first={!previous}>
                                    <aside></aside>
                                    <h3>{current?.name}</h3>
                                </LeadsHistoryTo>
                            </LeadsHistoryGrid>
                        </LeadsHistoryItem>
                    ) :  current?.type === 'action' ? (
                     
                        <LeadsHistoryItem key={i}>
                            <h6>{`${new Date(current.createdAt).toLocaleDateString('el-GR')}, ${new Date(current.createdAt).toLocaleTimeString('el-GR')}`} | Action status</h6>
                            <LeadsHistoryGrid>
                                {previous && (
                                    <>
                                        <LeadsHistoryFrom>
                                            <aside></aside>
                                            <h3>{previous?.action}</h3>
                                            {previous?.seller?.name ? (
                                                <p>by: {previous.seller?.name}</p>
                                            ) : null}
                                        </LeadsHistoryFrom>
                                        <LeadsHistoryIcon>
                                            <IconSvg Icon="Next" />
                                        </LeadsHistoryIcon>
                                    </>
                                )}
                                <LeadsHistoryTo first={!previous}>
                                    <aside></aside>
                                    <h3>{current?.action}</h3>
                                    {current?.seller?.name ? (
                                        <p>by: {current.seller?.name}</p>
                                    ) : null}
                                </LeadsHistoryTo>
                            </LeadsHistoryGrid>
                        </LeadsHistoryItem>
                    ) : 
                    null;
                })}
            </LeadsHistoryList>
        </LeadsHistory>
    );
}